// App imports
import './styles.scss';

const imagePath = "static/main/prices/header/";

export const Symbols = ({ propertyHoverInfo }: any) => {
	const currentItem = propertyHoverInfo.object;
	if (!currentItem) return <></>

	return (
		<div className="pictures-symbols">
			{currentItem.status === 1 && 
				<img 
					src={imagePath + "statusActive.svg"} 
					alt="status-active"
					style={{height: "15px"}}
				/>
			}
			{currentItem.new === 1 && 
				<img 
					src={imagePath + "newActive.svg"} 
					alt="new-active"
					style={{height: "15px"}}
				/>
			}
			{currentItem.pool === 1 && 
				<img 
					src={imagePath + "poolActive.svg"} 
					alt="pool-active"
					style={{height: "15px"}}
				/>
			}
			{currentItem.furnished === 1 && 
				<img 
					src={imagePath + "furnitureActive.svg"} 
					alt="furnished-active"
					style={{height: "15px"}}
				/>
			}
		</div>
	)
}

Symbols.displayName="Symbols";