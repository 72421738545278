export const Subtitle = () => {
	return (
		<div className="pages-subtitle">
			<div>a plataforma</div> 
			<div>de inteligência</div> 
			<div>imobiliária que</div>
			<div>cabe no seu bolso</div>
		</div>
	)
}

Subtitle.displayName="Subtitle";