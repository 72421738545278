export const Header = () => {
	return (
		<tr>
			<th></th>
			<th>dsv</th>
			<th>%</th>
			<th>valor</th>
			<th>área</th>
			<th>qtd</th>
			<th>média</th>
		</tr>	
	)
}

Header.displayName="Header";