// App imports
import './styles.scss';

export const Logo = () => {
	return (
		<img 
			className="gnrt-logo"
			src="static/logos/gnrt.svg" 
			alt="gnrt-logo"
		/>
	)
}

Logo.displayName="Logo";